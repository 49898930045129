
import { Component, Watch } from 'vue-property-decorator';
import VueBase from '@/components/VueBase.vue';
import { readSiteCode, readLogsQuantity, readSites } from '@/store/main/getters';
import { dispatchGetLogsQuantity } from '@/store/main/actions';
import moment from 'moment';
import { Bar } from 'vue-chartjs/legacy';

declare global {
  interface Date {
    addDays: any;
  }
}
Date.prototype.addDays = function(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

@Component
export default class Reports extends VueBase {
  public endDate: Date = new Date();
  public startDate: Date = this.endDate.addDays(-42);
  public timeUnit: string = 'day';
  public timeUnits: string[] = [
    // 'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'quarter',
    'year',
  ];
  public selectedStartDateMenu: boolean = false;
  public selectedEndDateMenu: boolean = false;
  public siteOptions: object[] = [
    {value: 'this_site_only', text:'This site only'},
    {value: 'all_sites_separately', text:'All Sites (separately)'},
    {value: 'all_sites_together', text:'All Sites (together)'},
  ];
  public siteOption: string = 'this_site_only';

  get chartOptions() {
    return {
      scales: {
        x: {
          type: 'time',
          time: {
            unit: this.timeUnit,
          },
          // ticks: {
          //   source: 'data',
          // },
        },
      },
      responsive: true,
    };
  }

  get siteCode() {
    return readSiteCode(this.$store);
  }

  get chartData() {
    const logsQuantity = readLogsQuantity(this.$store);
    let datasets: Array<{label: string, backgroundColor: string, data: Array<{x: Date, y: number}>}> = [];
    if (this.siteOption == 'all_sites_separately') {
      let currentSite;
      const sites = readSites(this.$store);
      sites.forEach((site) => {
        if (site.code == this.siteCode) {
          currentSite = site;
        }
      });
      const sitesByCode = {};
      sites.forEach((site) => {
        if (site.customer.id == currentSite.customer.id) {
          sitesByCode[site.code] = site.name;
        }
      });
      const callsBySite = {};
      const smsesBySite = {};
      logsQuantity.calls.forEach((q) => {
        if (q.site in callsBySite) {
          callsBySite[q.site].push({
            x: q.calldatetime,
            y: q.quantity,
          });
        } else {
          callsBySite[q.site] = [{
            x: q.calldatetime,
            y: q.quantity,
          }];
        }
      });
      logsQuantity.smses.forEach((q) => {
        if (q.site in smsesBySite) {
          smsesBySite[q.site].push({
            x: q.calldatetime,
            y: q.quantity,
          });
        } else {
          smsesBySite[q.site] = [{
            x: q.calldatetime,
            y: q.quantity,
          }];
        }
      });
      for (const siteCode in sitesByCode) {
        const siteName = sitesByCode[siteCode];
        datasets.push({
            label: `Call (${siteName})`,
            backgroundColor: 'blue',
            data: callsBySite[siteCode],
        });
        datasets.push({
            label: `Sms (${siteName})`,
            backgroundColor: 'yellow',
            data: smsesBySite[siteCode],
        });
      }
    } else {
      const dataCalls: Array<{x: Date, y: number}> = [];
      logsQuantity.calls.forEach((q) => dataCalls.push({
        x: q.calldatetime,
        y: q.quantity,
      }));
      const dataSmses: Array<{x: Date, y: number}> = [];
      logsQuantity.smses.forEach((q) => dataSmses.push({
        x: q.calldatetime,
        y: q.quantity,
      }));
      datasets = [{
          label: 'Call',
          backgroundColor: 'blue',
          data: dataCalls,
        },
        {
          label: 'Sms',
          backgroundColor: 'yellow',
          data: dataSmses,
        },
      ];
    }
    return {
      datasets,
    };
  }

  public async initialize() {
    if (this.siteCode) {
      await dispatchGetLogsQuantity(this.$store, {
        start: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'), end: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
        timeUnit: this.timeUnit, siteOption: this.siteOption
      });
    }
  }

  private async mounted() {
    await this.initialize();
  }

  @Watch('siteCode')
  private async watchSiteCode(val: string) {
    await this.initialize();
  }
}
